import {
  D_MAX_4X4_DATA,
  MUX_NEXT_PEAK_DATA,
  PICKUP_4DOOR_DATA,
  SPARK_DATA,
  V_CROSS_DATA,
} from '@/contents/lcv/product/cafe/lineup';
import { IMy24LineupItem } from '@/types/shared';

export const HOME_LINEUP_DATA: IMy24LineupItem[] = [
  {
    id: 1,
    preTitle: 'V-CROSS',
    title: V_CROSS_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt4d45471d4ededc5e/6641b223d94eaff6c7d2c235/lineup-v-cross.png',
      alt: V_CROSS_DATA.label,
      width: 600,
      height: 338,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blte456dec813c4503a/651668c0098aaa9f88ce49be/home-lineup-bg-v-cross-mobile.jpg',
      alt: V_CROSS_DATA.label,
      width: 1680,
      height: 720,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt391cae8117816279/651668f5474d4d73914f465d/home-lineup-bg-v-cross.jpg',
      alt: V_CROSS_DATA.label,
      width: 1680,
      height: 720,
    },
    button: {
      label: 'មើលពត៌មានបន្ថែម',
      icon: 'arrow-forward',
      color: 'button-gray',
      url: V_CROSS_DATA.url,
      isMinWidth: true,
    },
    pricePrefix: 'តម្លៃចាប់ពី',
    price: V_CROSS_DATA.price,
  },
  {
    id: 2,
    preTitle: 'D-MAX 4X4',
    title: D_MAX_4X4_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt964bb92d27cdf7b5/678db532e05cbf4047560a0e/cambodia-lineup-d-max-4x4-2025.png',
      alt: D_MAX_4X4_DATA.label,
      width: 600,
      height: 338,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt40baa710b8805390/679c8432a8d85533ddb6d7ab/bg-home-lineup-d-max-4x4.jpg',
      alt: D_MAX_4X4_DATA.label,
      width: 1680,
      height: 720,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltab3dfcc319281a55/679c8433e729224ad5536c6d/bg-home-lineup-d-max-4x4-desktop.jpg',
      alt: D_MAX_4X4_DATA.label,
      width: 1680,
      height: 720,
    },
    button: {
      label: 'មើលពត៌មានបន្ថែម',
      icon: 'arrow-forward',
      color: 'button-gray',
      url: D_MAX_4X4_DATA.url,
      isMinWidth: true,
    },
    pricePrefix: 'តម្លៃចាប់ពី',
    price: D_MAX_4X4_DATA.price,
  },
  {
    id: 3,
    preTitle: 'HI-LANDER',
    title: PICKUP_4DOOR_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt742a1269e1dc7c66/6641b28f64db55ab72845f56/lineup-4-door.png',
      alt: PICKUP_4DOOR_DATA.label,
      width: 600,
      height: 338,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt9f99dc2ea4f1adc2/65166a2623b7e78f5e6aa95c/home-lineup-bg-4-door-mobile.jpg',
      alt: PICKUP_4DOOR_DATA.label,
      width: 1680,
      height: 720,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blteee13e08dc626ff6/651669f1ec958dbcd8ea56b4/home-lineup-bg-4-door.jpg',
      alt: PICKUP_4DOOR_DATA.label,
      width: 1680,
      height: 720,
    },
    button: {
      label: 'មើលពត៌មានបន្ថែម',
      icon: 'arrow-forward',
      color: 'button-gray',
      url: PICKUP_4DOOR_DATA.url,
      isMinWidth: true,
    },
    pricePrefix: 'តម្លៃចាប់ពី',
    price: PICKUP_4DOOR_DATA.price,
  },
  {
    id: 4,
    preTitle: 'SPARK',
    title: SPARK_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt51d17c9e4dc80985/6641b26d8f19531bb4e5139c/lineup-spark.png',
      alt: SPARK_DATA.label,
      width: 600,
      height: 338,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltc51540266564afd1/651669a623b7e7b9f46aa95a/home-lineup-bg-spark-mobile.jpg',
      alt: SPARK_DATA.label,
      width: 1680,
      height: 720,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt180e8b8b245e62df/651669cccf50bf3f2afffa5c/home-lineup-bg-spark.jpg',
      alt: SPARK_DATA.label,
      width: 1680,
      height: 720,
    },
    button: {
      label: 'មើលពត៌មានបន្ថែម',
      icon: 'arrow-forward',
      color: 'button-gray',
      url: SPARK_DATA.url,
      isMinWidth: true,
    },
    pricePrefix: 'តម្លៃចាប់ពី',
    price: SPARK_DATA.price,
  },
  {
    id: 5,
    preTitle: 'MU-X',
    title: MUX_NEXT_PEAK_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt75ec291f96fa558f/678cab86ee8f38ab76aa2ae9/cambodia-lineup-mu-x-2025.png',
      alt: MUX_NEXT_PEAK_DATA.label,
      width: 600,
      height: 338,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt95b439ff5a3c15ad/6791ffb2d46d4553e0cddad3/bg-home-lineup-mu-x-mobile.jpg',
      alt: MUX_NEXT_PEAK_DATA.label,
      width: 1680,
      height: 720,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt3364eaf2f98ede1d/6791ffb28926c54a100385a9/bg-home-lineup-mu-x-desktop.jpg',
      alt: MUX_NEXT_PEAK_DATA.label,
      width: 1680,
      height: 720,
    },
    button: {
      label: 'មើលពត៌មានបន្ថែម',
      icon: 'arrow-forward',
      color: 'button-gray',
      url: MUX_NEXT_PEAK_DATA.url,
      isMinWidth: true,
    },
    pricePrefix: 'តម្លៃចាប់ពី',
    price: MUX_NEXT_PEAK_DATA.price,
  },
];

export const PRODUCT_LINEUP_DATA: IMy24LineupItem[] = [
  {
    id: 1,
    preTitle: 'V-CROSS',
    title: V_CROSS_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt4d45471d4ededc5e/6641b223d94eaff6c7d2c235/lineup-v-cross.png',
      alt: V_CROSS_DATA.label,
      width: 600,
      height: 338,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blte6ee10f4015aa408/65d2c00eebb2a2420d7b8b77/cambodia-bg-v-cross.jpg',
      alt: V_CROSS_DATA.label,
      width: 1680,
      height: 720,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blte6ee10f4015aa408/65d2c00eebb2a2420d7b8b77/cambodia-bg-v-cross.jpg',
      alt: V_CROSS_DATA.label,
      width: 1680,
      height: 720,
    },
    button: {
      label: 'មើលពត៌មានបន្ថែម',
      icon: 'arrow-forward',
      color: 'button-gray',
      url: V_CROSS_DATA.url,
      isMinWidth: true,
    },
    price: V_CROSS_DATA.price,
  },
  {
    id: 2,
    preTitle: 'D-MAX 4X4',
    title: D_MAX_4X4_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt964bb92d27cdf7b5/678db532e05cbf4047560a0e/cambodia-lineup-d-max-4x4-2025.png',
      alt: D_MAX_4X4_DATA.label,
      width: 600,
      height: 338,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blte3fa4e068b295c87/6789029cbc13497290d590e3/cambodia-bg-d-max-4x4.jpg',
      alt: D_MAX_4X4_DATA.label,
      width: 1680,
      height: 720,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blte3fa4e068b295c87/6789029cbc13497290d590e3/cambodia-bg-d-max-4x4.jpg',
      alt: D_MAX_4X4_DATA.label,
      width: 1680,
      height: 720,
    },
    button: {
      label: 'មើលពត៌មានបន្ថែម',
      icon: 'arrow-forward',
      color: 'button-gray',
      url: D_MAX_4X4_DATA.url,
      isMinWidth: true,
    },
    price: D_MAX_4X4_DATA.price,
  },
  {
    id: 3,
    preTitle: 'HI-LANDER',
    title: PICKUP_4DOOR_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt742a1269e1dc7c66/6641b28f64db55ab72845f56/lineup-4-door.png',
      alt: PICKUP_4DOOR_DATA.label,
      width: 600,
      height: 338,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt8728512a4319cf18/65d2c00e1671e63e2e2e9253/cambodia-bg-4-door.jpg',
      alt: PICKUP_4DOOR_DATA.label,
      width: 1680,
      height: 720,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt8728512a4319cf18/65d2c00e1671e63e2e2e9253/cambodia-bg-4-door.jpg',
      alt: PICKUP_4DOOR_DATA.label,
      width: 1680,
      height: 720,
    },
    button: {
      label: 'មើលពត៌មានបន្ថែម',
      icon: 'arrow-forward',
      color: 'button-gray',
      url: PICKUP_4DOOR_DATA.url,
      isMinWidth: true,
    },
    price: PICKUP_4DOOR_DATA.price,
  },
  {
    id: 4,
    preTitle: 'SPARK',
    title: SPARK_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt51d17c9e4dc80985/6641b26d8f19531bb4e5139c/lineup-spark.png',
      alt: SPARK_DATA.label,
      width: 600,
      height: 338,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt593e3db70bccee7f/65d2c00e27ec8db73e4a3e08/cambodia-bg-spark.jpg',
      alt: SPARK_DATA.label,
      width: 1680,
      height: 720,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt593e3db70bccee7f/65d2c00e27ec8db73e4a3e08/cambodia-bg-spark.jpg',
      alt: SPARK_DATA.label,
      width: 1680,
      height: 720,
    },
    button: {
      label: 'មើលពត៌មានបន្ថែម',
      icon: 'arrow-forward',
      color: 'button-gray',
      url: SPARK_DATA.url,
      isMinWidth: true,
    },
    price: SPARK_DATA.price,
  },
  {
    id: 5,
    preTitle: 'MU-X',
    title: MUX_NEXT_PEAK_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt75ec291f96fa558f/678cab86ee8f38ab76aa2ae9/cambodia-lineup-mu-x-2025.png',
      alt: MUX_NEXT_PEAK_DATA.label,
      width: 600,
      height: 338,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt5a3f28346de1eb61/6791fed6d9e0154b92961611/cambodia-bg-mu-x.jpg',
      alt: MUX_NEXT_PEAK_DATA.label,
      width: 1680,
      height: 720,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt5a3f28346de1eb61/6791fed6d9e0154b92961611/cambodia-bg-mu-x.jpg',
      alt: MUX_NEXT_PEAK_DATA.label,
      width: 1680,
      height: 720,
    },
    button: {
      label: 'មើលពត៌មានបន្ថែម',
      icon: 'arrow-forward',
      color: 'button-gray',
      url: MUX_NEXT_PEAK_DATA.url,
      isMinWidth: true,
    },
    price: MUX_NEXT_PEAK_DATA.price,
  },
];
